<template>
<v-container>
  <v-row v-if="isManagerIn"
         id="statistic_dashboard"
         cols="12"
  >
    <v-col
      cols="12"
      md="4"
    >
      <linear-statistic
        @click="goToRoute"
        class="mt-4"
        :title="$vuetify.lang.t('$vuetify.dashboard.orders')"
        :quantity="salesStatics.count"
        color="primary"
        :value="100"
      />
    </v-col>
    <v-col cols="12" md="4">
      <linear-statistic
        @click="goToRoute"
        class="my-4"
        :title="$vuetify.lang.t('$vuetify.menu.vending')"
        :quantity="salesStatics.totalPrice"
        :currency="getCurrency"
        color="info"
        :value="100"
      />
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <linear-statistic
        @click="goToRoute"
        class="my-4"
        :title="$vuetify.lang.t('$vuetify.dashboard.revenue')"
        :currency="getCurrency"
        :quantity="salesStatics.total"
        :icon="salesStatics.total  > 0 ? 'mdi-trending-up': 'mdi-trending-down'"
        :color="salesStatics.total  > 0 ? 'primary' : 'red'"
        :value="100"
      />
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'StaticsData',
  computed: {
    ...mapState('sale', ['salesByLimit', 'salesStatics', 'salesByProducts']),
    ...mapGetters('auth', ['user', 'access_permit', 'isManagerIn']),
    getCurrency () {
      return this.user ? this.user.id ? this.user.company.currency : '' : ''
    },
    getBenefits () {
      return this.salesStatics.income + this.salesStatics.others_incomes + this.salesStatics.income_credit + this.salesStatics.expenses_buy + this.salesStatics.other_expenses +
      this.salesStatics.sale_bills + this.salesStatics.bills + this.salesStatics.buy_bills
    },
    getIncomes () {
      return this.salesStatics.income + this.salesStatics.others_incomes + this.salesStatics.income_credit
    },
    getExpenses () {
      return (-1) * this.salesStatics.expenses_buy - this.salesStatics.other_expenses -
        this.salesStatics.sale_bills - this.salesStatics.bills - this.salesStatics.buy_bills
    }
  },
  methods: {
    goToRoute () {
      console.log('adasddasdasdasdasdas')
      this.$router.push({ name: 'summary' })
    }
  }
}
</script>

<style scoped>

</style>
