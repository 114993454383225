<template>
  <div class="page-dashboard">
    <app-loading v-show="loadingData"/>
    <help-doc v-if="showHelp"/>
    <statics-data v-if="!loadingData"/>
    <v-container v-if="!loadingData">
      <v-tabs
        v-model="tab"
        centered
      >
        <v-tab
          v-for="item in tabsData.tabName"
          :key="item.name"
        >
          <span style="text-transform: uppercase">{{
              item.name
            }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab"
      >
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col
                  v-for="(st, i) in statistic"
                  v-show="statistic.length > 0"
                  :key="i"
                  md="3"
                >
                  <mini-statistic
                    color="primary"
                    :icon="st.icon"
                    :sub-title="st.sub_title"
                    @click="goToClick(st.goToClick)"
                  />
                </v-col>
              </v-row>
              <v-card v-if="statisticDeny.length > 0">
                <v-card-subtitle>
                  {{
                    $vuetify.lang.t(
                      "$vuetify.sector.others"
                    )
                  }}
                </v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col
                      v-for="(st, i) in statisticDeny"
                      v-show="statistic.length > 0"
                      :key="i"
                      md="3"
                    >
                      <mini-statistic
                        disabled
                        color="red"
                        :icon="st.icon"
                        :sub-title="st.sub_title"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="isManagerIn">
          <v-card flat>
            <summary-total/>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <!--v-card flat>
            <config-menu/>
          </v-card-->
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import Material from 'vuetify/es5/util/colors'
import { mapActions, mapGetters, mapState } from 'vuex'
import localStore from '../../../config/localStorage'
import HelpDoc from '../../help/HelpDoc'
import SummaryTotal from '../../sales_by/commerce/Summary'
import StaticsData from './StaticsData'

export default {
  name: 'UserDashboard',
  components: {
    StaticsData,
    HelpDoc,
    // ConfigMenu,
    SummaryTotal
  },
  data () {
    return {
      firstDate: new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000),
      secondDate: new Date(),
      prevRouteName: '',
      tab: null,
      timer: '',
      loadingData: false,
      color: Material,
      statistic: [],
      statisticDeny: [],
      localSalesByPayments: [],
      localSalesByCategories: [],
      tabName: [
        {
          name: this.$vuetify.lang.t('$vuetify.dashboard.access'),
          icon: 'mdi-key',
          access: 'access'
        }
      ],
      trending: [
        {
          subheading: 'Email',
          headline: '15+',
          caption: 'email opens',
          percent: 15,
          icon: {
            label: 'email',
            color: 'info'
          },
          linear: {
            value: 15,
            color: 'info'
          }
        },
        {
          subheading: 'Tasks',
          headline: '90%',
          caption: 'tasks completed.',
          percent: 90,
          icon: {
            label: 'list',
            color: 'primary'
          },
          linear: {
            value: 90,
            color: 'success'
          }
        },
        {
          subheading: 'Issues',
          headline: '100%',
          caption: 'issues fixed.',
          percent: 100,
          icon: {
            label: 'bug_report',
            color: 'primary'
          },
          linear: {
            value: 100,
            color: 'error'
          }
        }
      ]
    }
  },
  computed: {
    ...mapState('sale', ['salesByLimit', 'salesStatics', 'salesByProducts']),
    ...mapState('auth', ['showHelp']),
    ...mapGetters('auth', ['user', 'access_permit', 'isManagerIn']),
    tabsData () {
      const result = {
        tabName: [],
        itemsTabs: []
      }
      this.tabName.forEach((v, i) => {
        if (this.access_permit[v.access]) {
          if (this.access_permit[v.access].title.value === true) {
            result.tabName.push(v)
          }
        }
      })
      return result
    },
    columnHeaders () {
      const columns = [
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'images'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          align: 'left',
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.netPrice'),
          align: 'left',
          value: 'netPrice'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.cant_operations'),
          value: 'totalOperations'
        }
      ]
      if (this.isManagerIn) {
        columns.push({
          text: this.$vuetify.lang.t('$vuetify.dashboard.revenue') + '($)',
          value: 'margin',
          select_filter: true
        })
      }
      return columns
    }
  },
  watch: {
    access_permit: function () {
      this.completeStaticTic()
    },
    salesByLimit: function () {
    }
  },
  async beforeMount () {
    if (this.isManagerIn) {
      this.tabName.push(
        {
          name: this.$vuetify.lang.t('$vuetify.menu.resume'),
          icon: 'mdi-key',
          access: 'summary'
        })
    }
    this.loadingData = true
    if (localStore.getTypeConnection() === 'partner') {
      this.$router.push({ name: 'partner_dashboard' })
    } else if (localStore.getTypeConnection() === 'admin') {
      this.$router.push({ name: 'admin_dashboard' })
    } else if (localStore.getTypeConnection() === 'beneficiary') {
      this.$router.push({ name: 'beneficiary_dashboard' })
    } else {
      await this.getUserLogin()
      await this.completeStaticTic()
      await this.loadData()
      this.loadingData = false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRouteName = from.name
    })
  },
  async created () {
    if (this.prevRouteName === 'pinlogin') {
      if (this.user.showHelp) {
        this.setShowHelp(true)
      }
    }
    if (localStore.getTypeConnection() !== 'partner' && localStore.getTypeConnection() !== 'admin' && localStore.getTypeConnection() !== 'beneficiary') { await this.loadData() }
    this.timer = await setTimeout(() => {
      this.loadData()
    }, 60000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    ...mapActions('user', ['getUserLogin']),
    ...mapActions('sale', ['getSaleByLimit', 'getSaleStatics', 'getSaleByProduct']),
    ...mapActions('article', ['getArticlesMerge']),
    ...mapActions('auth', ['setShowHelp']),
    async loadData () {
      await this.getSaleByLimit(5)
      await this.getSaleByProduct({
        shops: [],
        dates: [new Date(new Date().getTime() - 5 * 24 * 60 * 60 * 1000), new Date()],
        online: 'all'
      })
      await this.getSaleStatics({
        dates: [this.firstDate, this.secondDate]
      })
    },
    completeStaticTic () {
      this.statistic = []
      this.statisticDeny = []
      if (this.access_permit) {
        this.access_permit.shop.title.value === true
          ? this.statistic.push({
            icon: 'mdi-home',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_shop'
            ),
            goToClick: 'manager_shop'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_shop'
            )
          })
        this.access_permit.category.title.value === true
          ? this.statistic.push({
            icon: 'mdi-tag',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_category'
            ),
            goToClick: 'category_list'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_category'
            )
          })
        this.access_permit.article.title.value === true
          ? this.statistic.push({
            icon: 'mdi-shopping',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_article'
            ),
            goToClick: 'product_list'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_article'
            )
          })
        this.access_permit.modifier.title.value === true
          ? this.statistic.push({
            icon: 'mdi-tag-text-outline',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_mod'
            ),
            goToClick: 'modifiers_list'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_mod'
            )
          })
        this.access_permit.client.title.value === true
          ? this.statistic.push({
            icon: 'mdi-account-multiple',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_client'
            ),
            goToClick: 'clients_list'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_client'
            )
          })
        this.access_permit.box.title.value === true
          ? this.statistic.push({
            icon: 'mdi-database',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_boxes'
            ),
            goToClick: 'boxes'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_boxes'
            )
          })
        this.access_permit.refund.title.value === true
          ? this.statistic.push({
            icon: 'mdi-undo',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_refunds'
            ),
            goToClick: 'refund'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_refunds'
            )
          })
        this.access_permit.sales.title.value === true
          ? this.statistic.push({
            icon: 'mdi-currency-usd',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_vending'
            ),
            goToClick: 'vending'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_vending'
            )
          })
        /* this.access_permit.order.title.value === true
          ? this.statistic.push({
            icon: 'mdi-truck',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_order'
            ),
            goToClick: 'supply_product'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_vending'
            )
          }) */
        this.access_permit.supplier.title.value === true
          ? this.statistic.push({
            icon: 'mdi-account-switch',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_supplier'
            ),
            goToClick: 'supplier_list'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_supplier'
            )
          })
        this.access_permit.buy.title.value === true
          ? this.statistic.push({
            icon: 'mdi-cart',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_buy'
            ),
            goToClick: 'buy_products'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_buy'
            )
          })
        this.access_permit.summary.title.value === true
          ? this.statistic.push({
            icon: 'mdi-chart-bar',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_sell'
            ),
            goToClick: 'summary'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_sell'
            )
          })
        this.access_permit.access.title.value === true
          ? this.statistic.push({
            icon: 'mdi-key',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_access'
            ),
            goToClick: 'access'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_access'
            )
          })
        this.access_permit.employer.title.value === true
          ? this.statistic.push({
            icon: 'mdi-account-star',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_employer'
            ),
            goToClick: 'employer_list'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_employer'
            )
          })
        /* this.access_permit.filter(
          permit => permit.title.name === 'manager_assistence'
        )[0].title.value === true
          ? this.statistic.push({
            icon: 'mdi-cards',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_assistence'
            ),
            goToClick: 'assistance'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_assistence'
            )
          }) */
        /* this.access_permit.expense_category.title.value === true
          ? this.statistic.push({
            icon: 'mdi-marker-check',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_expense_category'
            ),
            goToClick: 'expense_category'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_expense_category'
            )
          }) */
        this.access_permit.exchange_rate.title.value === true
          ? this.statistic.push({
            icon: 'mdi-bank',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_exchange_rate'
            ),
            goToClick: 'exchange_rate'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_exchange_rate'
            )
          })
        this.access_permit.type_of_order.title.value === true
          ? this.statistic.push({
            icon: 'mdi-food',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_type_of_order'
            ),
            goToClick: 'type_of_order'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_type_of_order'
            )
          })
        this.access_permit.tax.title.value === true
          ? this.statistic.push({
            icon: 'mdi-wallet',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_tax'
            ),
            goToClick: 'tax_list'
          })
          : this.statisticDeny.push({
            icon: 'mdi-wallet',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_tax'
            )
          })
        this.access_permit.discount.title.value === true
          ? this.statistic.push({
            icon: 'mdi-cash-minus',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_discount'
            ),
            goToClick: 'discount'
          })
          : this.statisticDeny.push({
            icon: 'mdi-close',
            sub_title: this.$vuetify.lang.t(
              '$vuetify.access.access.manager_discount'
            )
          })
      }
    },
    goToClick (name) {
      this.$router.push({ name: name })
    }
  }
}
</script>
